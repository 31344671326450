@if (!itemUsedResult) {
    <div class="row">
        <div class="col-md-4 image-wrapper">
            <img class="item-img" [src]="item.ItemType.ImagePath" />
        </div>
        <div class="col-md-8 mt-3 mt-sm-0 content-wrapper">
            @if (containingShop && $any(item).ShopDescription && $any(item).ShopDescription.length > 0 && item; as shopItem) {
                <p class="item-description">{{ $any(shopItem).ShopDescription }}</p>
            } @else {
                <p class="item-description">{{ item.ItemType.Description }}</p>
            }

            <div class="accordion" id="itemActionAccordion">
                @if (containingShop && item; as shopItem) {
                    @if ($any(shopItem).Price && $any(shopItem).Price > 0) {
                        <button class="btn btn-primary" type="button" (click)="buyItem($any(shopItem))">Buy it!</button>
                    }
                } @else {
                    @if (getItemUses(item.ItemType) | async; as itemUses) {
                        @if (itemUses.length > 0) {
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne">
                                        Use Item
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#itemActionAccordion">
                                    <div class="accordion-body">
                                        @if (activePets | async; as pets) {
                                            @if (filterPetItemUses(itemUses); as petUses) {
                                                @if (petUses.length > 0) {
                                                    <div>
                                                        @if (petUses.length > 1 || pets.length > 1) {
                                                            <div class="row">
                                                                <div class="col">
                                                                    <select class="form-select" [(ngModel)]="selectedPetOption">
                                                                        <option value="null" selected>Select a use</option>
                                                                        @for (petUse of petUses; track petUse) {
                                                                            @for (pet of activePets | async; track pet) {
                                                                                <option [ngValue]="{ pet: pet, use: petUse }">
                                                                                    {{ petUse.Text.replace("{pet}", pet.Name) }}
                                                                                </option>
                                                                            }
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div class="col-auto">
                                                                    <button (click)="useItem(item)" class="btn btn-secondary">Use!</button>
                                                                </div>
                                                            </div>
                                                        } @else {
                                                            @if (pets.length == 1) {
                                                                <div class="col">
                                                                    <button
                                                                        (click)="useItemOnPet(item, { pet: pets[0], use: petUses[0] })"
                                                                        class="btn btn-secondary">
                                                                        {{ petUses[0].Text.replace("{pet}", pets[0].Name) }}
                                                                    </button>
                                                                </div>
                                                            }
                                                        }
                                                    </div>
                                                }
                                            }
                                        }
                                        @if (filterNonPetItemUses(itemUses); as noPetUses) {
                                            @if (noPetUses.length > 0) {
                                                <div>
                                                    @if (noPetUses.length > 1) {
                                                        <div class="row">
                                                            <div class="col">
                                                                <select class="form-select" [(ngModel)]="selectedNonPetOption">
                                                                    <option value="null" selected>Select a use</option>
                                                                    @for (noPetUse of noPetUses; track noPetUse) {
                                                                        <option [ngValue]="noPetUse">
                                                                            {{ noPetUse.Text }}
                                                                        </option>
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div class="col-auto">
                                                                <button (click)="useItem(item)" class="btn btn-secondary">Use!</button>
                                                            </div>
                                                        </div>
                                                    } @else {
                                                        <div class="col">
                                                            <button (click)="useItem(item, noPetUses[0])" class="btn btn-secondary">
                                                                {{ noPetUses[0].Text }}
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    }
                    @if (shops | async; as shopList) {
                        @if (shopList.length > 0) {
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        Put in Shop
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#itemActionAccordion">
                                    <div class="accordion-body">
                                        @if (shopList.length > 1) {
                                            <div class="row">
                                                <div class="col">
                                                    <select class="form-select" (change)="selectShop($event)">
                                                        <option selected>Pick a shop</option>
                                                        @for (shop of shopList; track shop) {
                                                            <option value="{{ shop.Id }}">{{ shop.Name }}</option>
                                                        }
                                                    </select>
                                                </div>
                                                <div class="col-auto">
                                                    <button (click)="moveToShop(item, selectedShop)" class="btn btn-secondary">Move</button>
                                                </div>
                                            </div>
                                        } @else {
                                            @if (shopList.length == 1) {
                                                <div class="col">
                                                    @for (shop of shopList; track shop) {
                                                        <button (click)="moveToShop(item, shop.Id)" class="btn btn-secondary">
                                                            Put in <strong>{{ shop.Name }}</strong>
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    }
                    @if (friends | async; as friendsList) {
                        @if (friendsList.length > 0) {
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree">
                                        Send to a Friend
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#itemActionAccordion">
                                    <div class="accordion-body">
                                        @if (friendsList.length > 1) {
                                            <div class="row">
                                                <div class="col">
                                                    <select class="form-select" (change)="selectFriend($event)">
                                                        <option selected>Choose a friend</option>
                                                        @for (friend of friendsList; track friend) {
                                                            @if (friend.FriendUsername; as friendUsername) {
                                                                <option value="{{ friendUsername }}">
                                                                    {{ friendUsername }}
                                                                </option>
                                                            }
                                                        }
                                                    </select>
                                                </div>
                                                <div class="col-auto">
                                                    <button (click)="sendToFriend(selectedFriend, item)" class="btn btn-secondary">
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        } @else {
                                            @if (friendsList.length == 1) {
                                                <div class="col">
                                                    <button
                                                        (click)="sendToFriend(friendsList[0].FriendUsername, item)"
                                                        class="btn btn-secondary">
                                                        Give to <strong>{{ friendsList[0].FriendUsername }}</strong>
                                                    </button>
                                                </div>
                                            }
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    }
                }
            </div>
        </div>
    </div>
} @else {
    <app-critter-state-update [stateUpdate]="itemUsedResult"></app-critter-state-update>
}
