import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Shop, ShopItem, ShopStockResponse } from '../dto';
import { AbstractService } from './abstractservice';
import { ApplicationService } from './application.service';
import { InventoryService } from './inventory.service';
import { JsonEnrichmentService } from './jsonmapper.service';

@Injectable({
    providedIn: 'root'
})
export class ShopService extends AbstractService {

    public activeUserShops: BehaviorSubject<Shop[]> = new BehaviorSubject([]);

    constructor(private http: HttpClient, private coreAppService: ApplicationService, private inventoryService: InventoryService, private jsonMapper: JsonEnrichmentService) {
        super(coreAppService.authService);
        coreAppService.authService.authenticatedUser$.subscribe(a => {
            if (!(this.activeUserShops.getValue()?.length) && this.authService.isAuthenticated()) {
                this.initializeActiveUserShops();
            }
        });
    }


    private initializeActiveUserShops(): void {
        this.http.get<Shop[]>(environment.apiUrl + '/shop/user/', this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                map(shops => shops?.map(s => new Shop(s))),
                catchError(this.handleError)
            ).subscribe(s => {
                if (s && s.length) {
                    const shops = this.activeUserShops.getValue();
                    shops.push(...s);
                    this.activeUserShops.next(shops);
                }
            });
    }


    public getShop(shopId: number): Observable<Shop> {
        return this.http.get<Shop>(environment.apiUrl + '/shop/' + shopId, this.httpOptionsAuthJson())
            .pipe(
                map(shop => new Shop(shop)),
                retry(2),
                catchError(this.handleError)
            );
    }

    public getAllShops(): Observable<Shop[]> {
        return this.http.get<Shop[]>(environment.apiUrl + '/shop/all/', this.httpOptionsAuthJson())
            .pipe(
                map(shops => shops.map(shop => new Shop(shop))),
                retry(2),
                catchError(this.handleError)
            );
    }

    public getShopItems(shopId: number): Observable<ShopItem[]> {
        return this.http.get<ShopStockResponse>(environment.apiUrl + '/shop/stock/' + shopId, this.httpOptionsAuthJson())
            .pipe(
                map(ssr => {
                    ssr = this.jsonMapper.map(ssr, ShopStockResponse);
                    return ssr.Items;
                }),
                retry(2),
                catchError(this.handleError)
            );
    }

    public getUserShops(userName: string): Observable<Shop[]> {
        return this.http.get<Shop[]>(environment.apiUrl + '/shop/user/' + userName, this.httpOptionsAuthJson())
            .pipe(
                map(shops => shops.map(shop => new Shop(shop))),
                retry(2),
                catchError(this.handleError)
            );
    }

    public updateShops(shop: Shop): Observable<Shop[]> {
        return this.http.put<Shop[]>(environment.apiUrl + '/shop/', shop, this.httpOptionsAuthJson())
            .pipe(
                map(shops => shops.map(shop => new Shop(shop))),
                retry(2),
                catchError(this.handleError)
            );
    }

    public updateShopItem(item: ShopItem): Observable<ShopItem> {
        return this.http.put<ShopItem>(environment.apiUrl + '/shop/stock/', item, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public addShop(shop: Shop): Observable<Shop> {
        return this.http.put<Shop>(environment.apiUrl + '/shop/', shop, this.httpOptionsAuthJson())
            .pipe(
                map(shop => new Shop(shop)),
                retry(2),
                catchError(this.handleError)
            );
    }

    public removeShopItem(item: ShopItem): Observable<ShopItem> {
        return this.http.delete<ShopItem>(environment.apiUrl + `/shop/stock/${item.ShopId}/${item.Id}/`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public purchaseShopItem(item: ShopItem, shop: Shop): Observable<unknown> {
        if (item.Price && item.Price > 0) {
            return this.http.patch<void>(environment.apiUrl + `/shop/purchase/${item.Id}/`, null, this.httpOptionsAuthJson())
                .pipe(
                    retry(1),
                    catchError(err => this.handlePurchaseError(err, item, shop)).bind(this),
                    tap({
                        next: () => {
                            setTimeout(() => {
                                this.inventoryService.insertItem(item);
                                this.coreAppService.userService.updateUserCash(item.Price * -1);
                                const itemIdx = shop.Items.findIndex(ssi => ssi === item);
                                shop.Items.splice(itemIdx, 1);
                            }, 250);
                        }
                    }),
                );
        }

        throw { error: 'No price!' };
    }

    protected handlePurchaseError(error: HttpErrorResponse, purchaseItem: ShopItem, shop: Shop) {
        if (!(error.error instanceof ErrorEvent)) {
            let errorMessage: string;
            let errorTitle: string;
            let imagePath: string;

            if (error.status == HttpStatusCode.FailedDependency) {
                errorMessage = 'You don\'t have enough crittercoins for that!';
                errorTitle = 'Uh oh!';
                imagePath = 'assets/images/ui/insufficient-funds.png';
            } else if (error.status == HttpStatusCode.Gone) {
                errorMessage = 'Oh no! Someone else bought that first!';
                errorTitle = 'Too slow!';
                imagePath = 'assets/images/ui/item-missing.png';
                const itemIdx = shop.Items.findIndex(ssi => ssi === purchaseItem);
                shop.Items.splice(itemIdx, 1);
            }
            this.coreAppService.displayError(errorMessage, errorTitle, imagePath, null, null);
        }
        return EMPTY;
    }

    /**
     * No response object, just 204 No Content
     */
    public removeShop(shop: Shop): Observable<void> {
        return this.http.delete<void>(environment.apiUrl + '/shop/' + shop.Id, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }


}