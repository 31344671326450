import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DynamicComponentService {

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    public injectView(viewContainer: ViewContainerRef, type: Type<any>, initializer: (c: ComponentRef<any>) => void): ComponentRef<any> {
        let newComponent = this.componentFactoryResolver.resolveComponentFactory(type).create(viewContainer.injector);
        initializer(newComponent);
        viewContainer.insert(newComponent.hostView);
        return newComponent;
    }
}
