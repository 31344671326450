<div class="results">
    <div class="row">
        <div class="col-auto">
            @for (petToUpdatesTuple of petUpdates.entries(); track petToUpdatesTuple) {
                <div>
                    <div class="row">
                        <div class="col-md-4">
                            <img class="pet-image" [src]="petToUpdatesTuple[0].ImagePath" />
                        </div>
                        <div class="col-md">
                            @for (petUpdate of petToUpdatesTuple[1]; track petUpdate) {
                                <div>
                                    {{ petToUpdatesTuple[0].Name }}
                                    @if (petUpdate.InitialState === petUpdate.FinalState) {
                                        is still <strong>{{ petUpdate.FinalState }}</strong
                                        >.
                                    } @else {
                                        was <strong>{{ petUpdate.InitialState }}</strong> but is now
                                        <strong>{{ petUpdate.FinalState }}</strong
                                        >.
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
    <div class="row">
        <div class="col-auto">
            @if (
                stateUpdate.PlayerStateUpdate &&
                ((stateUpdate.PlayerStateUpdate.AddedItems && stateUpdate.PlayerStateUpdate.AddedItems.length > 0) ||
                    (stateUpdate.PlayerStateUpdate.RemovedItems && stateUpdate.PlayerStateUpdate.RemovedItems.length > 0) ||
                    stateUpdate.PlayerStateUpdate.CashChangedAmount)
            ) {
                <div>
                    @if (stateUpdate.PlayerStateUpdate.AddedItems && stateUpdate.PlayerStateUpdate.AddedItems.length > 0) {
                        <div>
                            <h3>You gained:</h3>
                            @for (addItem of stateUpdate.PlayerStateUpdate.AddedItems; track addItem) {
                                <div class="list-item">
                                    <!--todo JAB split into angular component-->
                                    <app-item-card [item]="addItem" class="item-holder"></app-item-card>
                                </div>
                            }
                        </div>
                    }
                    @if (
                        stateUpdate.PlayerStateUpdate &&
                        stateUpdate.PlayerStateUpdate.RemovedItems &&
                        stateUpdate.PlayerStateUpdate.RemovedItems.length > 0
                    ) {
                        <div>
                            <h3>You lose:</h3>
                            @for (remItem of stateUpdate.PlayerStateUpdate.RemovedItems; track remItem) {
                                <div class="list-item">
                                    <app-item-card [item]="remItem" class="item-holder"></app-item-card>
                                </div>
                            }
                        </div>
                    }
                    @if (stateUpdate.PlayerStateUpdate && stateUpdate.PlayerStateUpdate.CashChangedAmount) {
                        <div>
                            @if (stateUpdate.PlayerStateUpdate.CashChangedAmount > 0) {
                                <div>
                                    <h3>You get:</h3>
                                    <span class="text-success"
                                        >{{ stateUpdate.PlayerStateUpdate.CashChangedAmount }}
                                        <img src="assets/images/icons/icon-critter-coin.svg" class="text-icon invert" />!</span
                                    >
                                </div>
                            } @else {
                                @if (stateUpdate.PlayerStateUpdate.CashChangedAmount < 0) {
                                    <div>
                                        <h3>You lose:</h3>
                                        <span class="text-danger"
                                            >{{ stateUpdate.PlayerStateUpdate.CashChangedAmount }}
                                            <img src="assets/images/icons/icon-critter-coin.svg" class="text-icon invert" />!</span
                                        >
                                    </div>
                                }
                            }
                        </div>
                    }
                </div>
            }
        </div>
    </div>

    @if (stateUpdate && stateUpdate.Messages && stateUpdate.Messages.length) {
        <div class="row justify-content-center">
            <div class="col-auto">
                @for (message of stateUpdate.Messages; track message) {
                    <div>
                        <div class="d-flex align-items-center">
                            <div class="ms-3" style="flex-basis: 100%">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    }

    @if (stateUpdate.LottieId) {
        <div class="lottie">
            <lottie-player
                [src]="'/assets/images/animations/' + stateUpdate.LottieId + '.json'"
                background="transparent"
                speed="1"
                style="height: 150%; width: auto; min-width: 18rem"
                autoplay="true"></lottie-player>
        </div>
    }
</div>
