import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryItem, Shop, ShopItem } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { ShopService } from 'src/app/services/shop.service';
import { View } from '../../base.component';
import { GenericModalComponent } from '../../ui/generic-modal/generic-modal.component';
import { InventoryItemOptionsComponent } from '../inventory-item-options/inventory-item-options.component';

@Component({
    selector: 'app-item-card',
    templateUrl: './item-card.component.html',
    styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent extends View implements OnInit {

    @Input() item: InventoryItem | ShopItem;
    @Input() containingShop: Shop | null;
    @Input() isInventoryContext: boolean;
    @Input() isShopManagementContext: boolean;
    @Input() isQuestContext: boolean;
    @Input() quantity: number;

    @Input() genericCenteredCard: boolean;


    baseOptions: ItemCardOption[] = [];

    purchased = false;


    constructor(private shopService: ShopService, private inventoryService: InventoryService, private coreAppService: ApplicationService) {//private route: ActivatedRoute, router: Router) {
        super(coreAppService.authService, coreAppService.uiStateService);
    }
    ngOnInit(): void {
        if (this.containingShop) {
            if (!this.isShopManagementContext && !this.isInventoryContext && this.item instanceof ShopItem && (<ShopItem>this.item).Price) {
                this.baseOptions.push({
                    buttonClasses: ['btn-primary'],
                    text: "Buy it!",
                    doOption: (e) => { e.stopPropagation(); this.buy(); }

                });
            }
        } else if (this.isInventoryContext) {
            this.baseOptions.push({
                buttonClasses: ['btn-light'],
                text: "Options",
                doOption: () => { }
            });
        }
    }

    onClick() {
        if (!this.isShopManagementContext && (this.isInventoryContext || this.containingShop)) {
            this.displayItemOptions()
        }
    }

    doOption(option: ItemCardOption, event: MouseEvent) {
        option.doOption(event);
    }


    public updateItem(item: ShopItem): void {
        this.subscribe(this.shopService.updateShopItem(item), null, ((err: HttpErrorResponse) => {
            if (err.status == HttpStatusCode.Gone) {
                this.failToRemoveItem(item);
            }
        }).bind(this));
    }

    public removeItem(item: ShopItem): void {
        this.subscribe(this.shopService.removeShopItem(item),
            i => {
                this.removeItemFromList(item);
                this.coreAppService.modalService.dismissAll();
            },
            ((err: HttpErrorResponse) => {
                if (err.status == HttpStatusCode.Gone) {
                    this.failToRemoveItem(item);
                }
            }).bind(this));
    }

    private removeItemFromList(item: ShopItem) {
        const indexToRemove = this.containingShop.Items.findIndex(i => item.Id === i.Id);
        if (indexToRemove !== -1) {
            this.containingShop.Items.splice(indexToRemove, 1);
        }
    }

    private failToRemoveItem(item: ShopItem) {
        this.coreAppService.displayError('Oh no! Someone else bought that first!',
            'Too slow!',
            'assets/images/ui/item-missing.png', null, null);
        this.removeItemFromList(item);
    }

    private buy() {
        this.subscribe(this.shopService.purchaseShopItem((<ShopItem>this.item), this.containingShop),
            () => { this.purchased = true });
    }

    private displayItemOptions() {
        this.coreAppService.uiStateService.openModalWithComponent(
            { size: 'lg', },
            this.containingShop?.Name ?? "Inventory",
            this.item.ItemType.Name,
            InventoryItemOptionsComponent,
            GenericModalComponent,
            (invOptions: InventoryItemOptionsComponent, openedModal: NgbActiveModal) => { invOptions.item = this.item; invOptions.containingShop = this.containingShop; }
        );
    }

}

class ItemCardOption {
    buttonClasses: string[];
    text: string;
    doOption: (e: MouseEvent) => void
}
