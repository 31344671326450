import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationType } from 'angular-to-phaser';
import { BehaviorSubject, catchError, map, of, retry, shareReplay, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Notification } from '../dto';
import { AbstractService } from './abstractservice';
import { ApplicationService } from './application.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends AbstractService {

    public persistentPrivateNotifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);
    public unreadPrivateNotifications = this.persistentPrivateNotifications.pipe(
        map(ns => ns.filter(n => !n.ReadDate && (
            n.NotificationType == NotificationType.FriendshipRequest || n.NotificationType == NotificationType.PrivateMessage))),
        shareReplay(1));

    public persistentMessageboardNotifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);
    public unreadMessageboardNotifications = this.persistentMessageboardNotifications.pipe(
        map(ns => ns.filter(n => !n.ReadDate && n.NotificationType == NotificationType.MessageBoard)),
        shareReplay(1));

    constructor(private http: HttpClient, private coreAppService: ApplicationService) {
        super(coreAppService.authService);

        var authSubscription = coreAppService.userService.activeUser$.subscribe(user => {
            if (user && user.Username && this.authService.isAuthenticated()) {
                this.coreAppService.notificationService.initializeConnection();
                this.retrieveUnreadNotifications([NotificationType.PrivateMessage, NotificationType.FriendshipRequest], this.persistentPrivateNotifications);
                this.retrieveUnreadNotifications([NotificationType.MessageBoard], this.persistentMessageboardNotifications);
            }
        });
    }


    public retrieveUnreadNotifications(types: NotificationType[], updateSubject: BehaviorSubject<Notification[]>) {
        this.http.get<Notification[]>(`${environment.apiUrl}/notification/unread/?type=${types.map(t => t.valueOf()).join('&type=')}`, this.httpOptionsAuthJson())
            .pipe(
                retry(1),
                map(notifications => notifications.map(n => new Notification(n, this.coreAppService.userService.activeUser$.getValue().Username, this.coreAppService.jsonMapper.jsonEnricher()))),
                catchError(this.handleError),
            ).subscribe(sendResponse => {
                this.insertNotifications(sendResponse, updateSubject);
            });
    }

    public markNotificationsAsRead(...readNotifications: Notification[]) {
        if (!readNotifications?.length || readNotifications.findIndex(n => !n) >= 0) return of();
        return this.http.patch(environment.apiUrl + '/notification/multi/', readNotifications.map(n => n.Id), this.httpOptionsAuthJson())
            .pipe(
                retry(1),
                catchError(this.handleError),
                map(() => readNotifications.map(n => new Notification(n, this.coreAppService.userService.activeUser$.getValue().Username, this.coreAppService.jsonMapper.jsonEnricher()))),
                tap({
                    next: () => {
                        let updateSubject = this.determineUpdateSubject(readNotifications[0].NotificationType);
                        readNotifications.forEach(n => n.ReadDate = new Date());
                        this.insertNotifications(readNotifications, updateSubject);
                    }
                }));
    }

    public addNotification(notification: Notification) {
        if (!notification) return;
        let updateSubject = this.determineUpdateSubject(notification.NotificationType);
        this.insertNotifications([notification], updateSubject)
    }

    public removeNotification(notification: Notification) {
        if (!notification) return;
        let updateSubject = this.determineUpdateSubject(notification.NotificationType);
        this.removeNotifications([notification], updateSubject)
    }

    private determineUpdateSubject(notificationType: NotificationType) {
        switch (notificationType) {
            case NotificationType.FriendshipRequest:
            case NotificationType.PrivateMessage: return this.persistentPrivateNotifications; break;
            case NotificationType.MessageBoard: return this.persistentMessageboardNotifications; break;
            default: throw "Invalid NotificationType!";
        }
    }

    private insertNotifications(notifications: Notification[], updateSubject: BehaviorSubject<Notification[]>) {
        var oldNotes = updateSubject.getValue();
        var oldNotes = oldNotes.filter(old => notifications.findIndex(newNote => newNote.Id == old.Id) === -1);  //no duplicates
        oldNotes.push(...notifications);
        oldNotes.sort((a, b) => a.CreateDate.valueOf() - b.CreateDate.valueOf());
        updateSubject.next(oldNotes);
    }

    private removeNotifications(notifications: Notification[], updateSubject: BehaviorSubject<Notification[]>) {
        var oldNotes = updateSubject.getValue();
        var oldNotes = oldNotes.filter(old => notifications.findIndex(newNote => newNote.Id == old.Id) === -1);  //remove matches
        oldNotes.sort((a, b) => a.CreateDate.valueOf() - b.CreateDate.valueOf());
        updateSubject.next(oldNotes);
    }




}
