import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemFunctionality } from 'angular-to-phaser';
import { Observable, of } from 'rxjs';
import { NestableAuthenticatedView } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Friendship, GameStateResponse, InventoryItem, ItemTypeConfig, ItemUse, PetDTO, Shop, ShopItem } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { InboxService } from 'src/app/services/inbox.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { PetService } from 'src/app/services/pet.service';
import { ShopService } from 'src/app/services/shop.service';

@Component({
    selector: 'app-inventory-item-options',
    templateUrl: './inventory-item-options.component.html',
    styleUrls: ['./inventory-item-options.component.scss']
})
export class InventoryItemOptionsComponent extends NestableAuthenticatedView implements OnInit {

    @Input() item: InventoryItem | ShopItem
    @Input() containingShop: Shop;

    friends: Observable<Friendship[]>;
    shops: Observable<Shop[]>;
    activePets: Observable<PetDTO[]>;

    selectedFriend: string;
    selectedShop: number;
    selectedPet: PetDTO;
    itemUsedResult: GameStateResponse;

    selectedPetOption: { pet: PetDTO; use: ItemUse };
    selectedNonPetOption: ItemUse;

    constructor(private activeModal: NgbActiveModal, private inventoryService: InventoryService, private inboxService: InboxService, private shopService: ShopService, private petService: PetService, route: ActivatedRoute, router: Router, private coreAppService: ApplicationService) {
        super(router, route, null, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
        this.friends = this.inboxService.friendsList$;
        this.activePets = this.petService.activeUserPets.asObservable();
        this.shops = this.shopService.activeUserShops;
    }


    ngOnInit(): void {
        console.log("organge")
    }


    selectFriend(event: any) {
        this.selectedFriend = event.target.value;
    }

    sendToFriend(friend: string, item: InventoryItem) {
        this.inventoryService.giftItem(item, friend);
        return true;
    }

    private retrieveItemUsesFromRemote(itc: ItemTypeConfig) {
        this.subscribe(this.inventoryService.getItemUses(itc), x => this.itemUses[itc.Id] = x);
    }

    public getItemUses(itc: ItemTypeConfig) {
        if (this.containingShop) {
            return of();
        }
        if (!this.itemUses[itc.Id]) {
            this.retrieveItemUsesFromRemote(itc);
        }
        return of(this.itemUses[itc.Id]);
    }

    public filterPetItemUses(itemUses: ItemUse[]) {
        if (itemUses === null || itemUses.length === 0) { return null; }

        return itemUses.filter(i => i.ItemFunctionality === ItemFunctionality.FeedToPet || i.ItemFunctionality === ItemFunctionality.PlayWithPet);
    }


    public filterNonPetItemUses(itemUses: ItemUse[]) {
        if (itemUses === null || itemUses.length === 0) { return null; }

        return itemUses.filter(i => i.ItemFunctionality === ItemFunctionality.OpenContainer);
    }

    private itemUses: ItemUse[][] = [];

    public useItemOnPet(item: InventoryItem, selection: { pet: PetDTO; use: ItemUse } = null) {
        if (selection) { this.selectedPetOption = selection; }

        if (item && this.selectedPetOption && this.selectedPetOption.pet && this.selectedPetOption.use) {
            this.inventoryService.useItem(item, this.selectedPetOption.use.ItemFunctionality, this.selectedPetOption.pet.Id).subscribe({
                next: this.showCritterStateUpdate.bind(this),
                error: () => {
                }
            })
            return true;
        }
        return false;
    }

    buyItem(item: ShopItem) {
        if (this.containingShop) {
            this.activeModal?.close();
            this.subscribe(this.shopService.purchaseShopItem(item, this.containingShop), null);
        }
    }


    public useItem(item: InventoryItem, selection: ItemUse = null) {
        if (selection) { this.selectedNonPetOption = selection; }

        if (item && this.selectedNonPetOption) {
            this.inventoryService.useItem(item, this.selectedNonPetOption.ItemFunctionality, null).subscribe({
                next: this.showCritterStateUpdate.bind(this),
                error: () => {
                }
            });
            return true;
        }
        return false;
    }

    private showCritterStateUpdate(gsr: GameStateResponse) {
        if (gsr.PetChanges?.length || gsr.PlayerStateUpdate?.AddedItems?.length || gsr.PlayerStateUpdate?.CashChangedAmount) {
            this.itemUsedResult = gsr;
        } else {        //if(gsr.PlayerStateUpdate?.RemovedItems?.find(i => i.Id == this.item.Id)){
            this.activeModal.close();
        }
    }

    public selectShop(event: any) {
        this.selectedShop = parseInt(event.target.value);
    }

    public moveToShop(item: InventoryItem, shop: number): boolean {
        this.inventoryService.moveItemtoShop(item, shop);
        return true;
    }
}
