// import { Component, Input, OnInit } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UIStateService } from '../../services/ui-state.service';
import { NestableAuthenticatedView } from '../base.component';
import { MainpageComponent } from '../mainpage/mainpage.component';
import { IEphemeralChatBroker } from './iephemeral-chat-broker';

@Component({
    selector: 'app-ephemeral-chat',
    templateUrl: './ephemeral-chat.component.html',
    styleUrls: ['./ephemeral-chat.component.scss']
})
export class EphemeralChatComponent extends NestableAuthenticatedView implements OnInit {

    @Input() ephemeralChatProvider: IEphemeralChatBroker;
    @Input() injectedClass: string;


    public static readonly SYSTEM_SENDER: string = 'SYSTEM';
    public readonly SYSTEM_SENDER = EphemeralChatComponent.SYSTEM_SENDER; //expose to ng template

    public activeMessage = '';
    public messages: EphemeralMessage[] = [];

    constructor(router: Router, route: ActivatedRoute, authService: AuthService, uiStateService: UIStateService) {
        super(router, route, null, authService, MainpageComponent.route, uiStateService);
    }

    public sendMessage(message: string) {
        if (message.length > 0) {
            this.ephemeralChatProvider.sendChat(message, this.activeUser.Username); //no customizeable names here
            this.messages.push(new EphemeralMessage(this.activeUser.Username, message, true));
            this.activeMessage = '';
        }
    }

    public receiveMessage(message: string, sender: string) {
        this.messages.push(new EphemeralMessage(sender, message, false));
    }

    ngOnInit(): void {
        this.ephemeralChatProvider.onReceiveMessage(this.receiveMessage.bind(this));
    }
}

class EphemeralMessage {

    public constructor(sender: string, message: string, myMessage: boolean) {
        this.sender = sender;
        this.message = message;
        this.timeReceived = new Date();
        this.myMessage = myMessage;
    }

    public sender: string;
    public message: string;
    public timeReceived: Date;
    public myMessage = false;
}
