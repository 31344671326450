import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EphemeralChatComponent } from 'src/app/components/ephemeral-chat/ephemeral-chat.component';
import { InventoryItemOptionsComponent } from 'src/app/components/items/inventory-item-options/inventory-item-options.component';
import { ItemCardComponent } from 'src/app/components/items/item-card/item-card.component';
import { CritterStateUpdateComponent } from 'src/app/components/ui/critter-state-update/critter-state-update.component';
import { GenericModalComponent } from 'src/app/components/ui/generic-modal/generic-modal.component';



@NgModule({ declarations: [
        EphemeralChatComponent,
        ItemCardComponent,
        GenericModalComponent,
        CritterStateUpdateComponent,
        InventoryItemOptionsComponent
    ],
    exports: [EphemeralChatComponent, ItemCardComponent, GenericModalComponent, CritterStateUpdateComponent, InventoryItemOptionsComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [RouterModule.forChild([]),
        CommonModule,
        FormsModule,
        NgbModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule { }
