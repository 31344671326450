<div class="modal-header">
    <h2 class="modal-title">{{ modalTitle }}</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelModal()"></button>
</div>
<div [class]="injectedClass" class="modal-body">
    <ng-template #dynamic></ng-template>
    @if (internalRoute) {
        <router-outlet name="interactormodaloutlet"></router-outlet>
        <!-- used for Nav Links --->
    }
</div>
@if (breadcrumb && !navigationOverride) {
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="closeModal()">
            {{ "Go Back to " + breadcrumb }}
        </button>
    </div>
}
