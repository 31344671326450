import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericSelectorTargets } from 'angular-to-phaser';
import { InventoryItem, ItemTypeConfig, PetDTO, Shop, UserArgumentBag } from 'src/app/dto';
import { ApplicationService } from 'src/app/services/application.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { ShopService } from 'src/app/services/shop.service';
import { CompletableActionView, IOverrideModalNavigation, ITakeServerMetadata } from '../base.component';
import { GenericModalComponent } from '../ui/generic-modal/generic-modal.component';

@Component({
    selector: 'app-generic-selector',
    templateUrl: './generic-selector.component.html',
    styleUrls: ['./generic-selector.component.scss']
})
export class GenericSelectorComponent extends CompletableActionView implements OnInit, ITakeServerMetadata, IOverrideModalNavigation {

    public static route: String = 'genericSelector';
    selectorTarget: GenericSelectorTargets;
    parentModal: GenericModalComponent;
    message: string = '';
    title: string = '';
    injectedClass: string;
    @Input() maxSelection: number = 1;

    public targets = GenericSelectorTargets; //expose enum to angular

    public items: any[];
    selectedItems: any[] = [];

    constructor(private coreAppService: ApplicationService, private shopService: ShopService, private inventoryService: InventoryService, private route: ActivatedRoute, router: Router) {
        super(coreAppService.authService, coreAppService.uiStateService);
    }

    ngOnInit(): void { }

    public initSelectorTarget(genericSelectorType: GenericSelectorTargets, selectorFunction: (item: any) => boolean) {
        this.selectorTarget = genericSelectorType;
        this.injectedClass = GenericSelectorTargets[genericSelectorType];
        switch (this.selectorTarget) {
            case GenericSelectorTargets.Shops: this.route.params.subscribe(params => {
                this.items = this.shopService.activeUserShops.getValue();
            }); break;
            case GenericSelectorTargets.InventoryItems: this.route.params.subscribe(params => {
                this.items = this.inventoryService.ActiveUserInventory.getValue();
            }); break;
        }
        if (selectorFunction) {
            this.items = this.items.filter(i => selectorFunction(i));
        }
    }

    public initModalParentForNav(parentModal: GenericModalComponent) {
        this.parentModal = parentModal
        parentModal.navigationOverride = true;
    }

    public acceptServerInput(title: string, description: string, injectedClass: string) {
        this.title = title;
        this.message = description;
        this.injectedClass = injectedClass ?? this.injectedClass;
    }

    selectItem(item: any) {
        if ((this.maxSelection == null || this.selectedItems.length <= this.maxSelection) && !this.selectedItems.includes(item)) {
            this.selectedItems.push(item);
        } else if (this.selectedItems.includes(item)) {
            this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        }
    }

    finalizeSelection() {
        if (this.selectedItems) {
            this.coreAppService.UserArgumentBag = new UserArgumentBag();
            switch (this.selectorTarget) {
                case GenericSelectorTargets.Shops: this.coreAppService.UserArgumentBag.ShopIds.push(...(<Shop[]>this.selectedItems).map(shop => shop.Id)); break;
                // case GenericSelectorTarget.Pets: this.coreAppService.UserArgumentBag.Pets.push(...(<PetDTO[]>this.selectedItems).map(petDTO => petDTO.Id)); break;
                case GenericSelectorTargets.InventoryItems: this.coreAppService.UserArgumentBag.ItemIds.push(...(<InventoryItem[]>this.selectedItems).map(invItem => invItem.Id)); break;
                // case GenericSelectorTarget.ItemTypes: this.coreAppService.UserArgumentBag..push(...(<ItemTypeConfig[]>this.selectedItems).map(itemTypeConfig => itemTypeConfig.Id)); break;
                default: throw `Invalid Generic Selector Target: ${this.selectorTarget}`;
            }
            this.completeAction(this);
        } else {
            this.cancel();
        }
    }

    cancel() {
        this.parentModal.activeModal.dismiss();
    }

    selectProperty(item: any) {
        switch (this.selectorTarget) {
            case GenericSelectorTargets.Shops: return (<Shop>item).Name;
            case GenericSelectorTargets.Pets: return (<PetDTO>item).Name;
            case GenericSelectorTargets.InventoryItems: return (<InventoryItem>item).ItemType.Name;
            case GenericSelectorTargets.ItemTypes: return (<ItemTypeConfig>item).Name;
            default: throw `Invalid Generic Selector Target: ${this.selectorTarget}`;
        }
    }
}