import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirectActionRecipientType } from 'angular-to-phaser';
import { catchError, map, Observable, retry, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import Constants from '../constants/consts';
import { Interaction, Npc, Zone } from '../dto';
import { AbstractService } from './abstractservice';
import { ApplicationService } from './application.service';
import { InventoryService } from './inventory.service';
import { PetService } from './pet.service';

@Injectable({
    providedIn: 'root'
})
export class WorldService extends AbstractService {

    constructor(private httpClient: HttpClient, private coreAppService: ApplicationService, private inventoryService: InventoryService, private petService: PetService) {
        super(coreAppService.authService);
    }

    public getWorld(): Observable<Zone[]> {
        return this.httpClient.get<Zone[]>(environment.apiUrl + '/world/', this.httpOptionsAuthJson())
            .pipe(
                map(zones => zones.map(zone => new Zone(zone))),
                retry(2),
                catchError(this.handleError)
            );
    }

    public getZone(externalId: string): Observable<Zone> {
        return this.httpClient.get<Zone>(environment.apiUrl + `/world/zone/${externalId}`, this.httpOptionsAuthJson())
            .pipe(
                map(zone => new Zone(zone)),
                retry(2),
                catchError(this.handleError)
            );
    }

    public getNpc(npcId: string): Observable<Npc> {
        return this.httpClient.get<Npc>(environment.apiUrl + `/world/npc/${npcId}`, this.httpOptionsAuthJson())
            .pipe(
                map(npc => new Npc(npc)),
                retry(2),
                catchError(this.handleError),
            );
    }

    public retrieveNpcOptions(npcId: string): Observable<Interaction> {
        return this.httpClient.post<Interaction>(environment.apiUrl + `/world/npc/${npcId}/interact/hello`, null, this.httpOptionsAuthJson())
            .pipe(
                map(interaction => {
                    let petName = this.petService.activeUserPets.getValue()[0].Name;
                    if (interaction && interaction.Message)
                        interaction.Message = interaction.Message.replace(Constants.PET_NAME_REPLACE_TOKEN, petName);
                    return new Interaction(interaction, this.coreAppService.jsonMapper.jsonEnricher());
                }),
                retry(2),
                catchError(this.handleError),
            );
    }


    public retrieveZoneOptions(zoneId: string): Observable<Interaction> {
        return this.httpClient.post<Interaction>(environment.apiUrl + `/world/zone/${zoneId}/interact/hello`, null, this.httpOptionsAuthJson())
            .pipe(
                map(interaction => {
                    let petName = this.petService.activeUserPets.getValue()[0].Name;
                    if (interaction && interaction.Message)
                        interaction.Message = interaction.Message.replace(Constants.PET_NAME_REPLACE_TOKEN, petName);
                    return new Interaction(interaction, this.coreAppService.jsonMapper.jsonEnricher());
                }),
                retry(2),
                catchError(this.handleError),
            );
    }

    public submitAction(externalId: string, action: number, recipientType: DirectActionRecipientType, postBody: any = null): Observable<Interaction> {
        let recipientTypeUrl;
        switch (recipientType) {
            case DirectActionRecipientType.Npc:
                recipientTypeUrl = 'npc';
                break;
            case DirectActionRecipientType.Zone:
                recipientTypeUrl = 'zone';
                break;
            case DirectActionRecipientType.Quest:
                recipientTypeUrl = 'quest';
                break;
            default: throw `WorldService is missing recipient type URL conversion for ${recipientType} - ${DirectActionRecipientType[recipientType]}`
        }

        return this.httpClient.post<Interaction>(environment.apiUrl + `/world/${recipientTypeUrl}/${externalId}/interact/${action}`, postBody, this.httpOptionsAuthJson())
            .pipe(
                map(interaction => {
                    let petName = this.petService.activeUserPets.getValue()[0].Name;
                    if (interaction && interaction.Message)
                        interaction.Message = interaction.Message.replace(Constants.PET_NAME_REPLACE_TOKEN, petName);
                    return new Interaction(interaction, this.coreAppService.jsonMapper.jsonEnricher());
                }),
                tap(interaction => Interaction.distributeGameStateToServices(interaction, this.inventoryService, this.coreAppService.userService, this.petService)),
                retry(2),
                catchError(this.handleError),
            );
    }



}
