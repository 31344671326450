<div class="eph-wrapper" [ngClass]="injectedClass">
  <div class="eph-message-container">
    @for (message of messages; track message) {
      <div class="eph-message-item" [ngClass]="{'eph-system-message': message.sender === SYSTEM_SENDER}">
        <span class="eph-message-sender"><strong>{{message.sender}}: </strong></span>
        <span class="eph-message-body">{{message.message}}</span><br />
        <span class="eph-message-date" style="font-size: 70%;">{{message.timeReceived | date: 'M/d, h:mm a' }}</span>
      </div>
    }
  </div>

  <div class="eph-message-input-container">
    <form class="d-flex">
      <input class="eph-message-input form-control me-2" type="text" name="message" placeholder="Type a message"
        [(ngModel)]="activeMessage" required>
        <button class="eph-message-send-button btn btn-block" type="submit" (click)="sendMessage(activeMessage)"><i
        class="fa fa-paper-plane"></i></button>
      </form>
    </div>
  </div>