import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, OnInit, QueryList, Type, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { View } from 'src/app/components/base.component';
import Constants from 'src/app/constants/consts';
import { AuthService } from 'src/app/services/auth.service';
import { DynamicComponentService } from 'src/app/services/dynamic-component.service';
import { UIStateService } from 'src/app/services/ui-state.service';

@Component({
    selector: 'app-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent extends View implements OnInit, AfterViewInit {

    internalRoute: string;
    breadcrumb: string;
    modalTitle: string;
    navigationOverride: boolean = false;

    public activeModal: NgbActiveModal;
    injectedClass: string = '';
    private injectedComponentType: Type<any>;
    private componentDataInitializerFunc: (c: any /** SHOULD BE A COMPONENT FROM COMPONENTREF.INSTANCE */, openedModal: NgbActiveModal, parentModal: GenericModalComponent) => void;
    private originalNavigation: string;


    @ViewChild('dynamic', { read: ViewContainerRef }) injectedComponentViewRef: ViewContainerRef
    @ViewChildren(RouterOutlet) routerOutlet: QueryList<RouterOutlet>

    constructor(private changeDetector: ChangeDetectorRef,
        private router: Router,
        private dynamicService: DynamicComponentService,
        authService: AuthService,
        uiStateService: UIStateService) {
        super(authService, uiStateService);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (this.routerOutlet.length && this.routerOutlet.first.component) {
            this.componentDataInitializerFunc(this.routerOutlet.first.component, this.activeModal, this);
        }

        if (this.injectedComponentType && this.componentDataInitializerFunc) {
            this.dynamicService.injectView(this.injectedComponentViewRef, this.injectedComponentType, (c: ComponentRef<any>) => this.componentDataInitializerFunc(c.instance, this.activeModal, this));
            this.changeDetector.detectChanges();
        }
        this.subscribe(this.uiStateService.activeTaskEventEmitter, (val => { if (val) { this.closeModal() } }));
    }

    public navigateToRouteForModal(path: string, breadcrumb: string, modalTitle: string, initializer: (c: ComponentRef<any>, openedModal: NgbActiveModal, parentModal: GenericModalComponent) => void) {
        var pathParts = path.split('/');
        var pathArgs = pathParts.length > 1 ? pathParts.pop() : '';
        var modulePath = Constants.OTHER_ROUTED_MODULES.includes(pathParts[0]) ? `${pathParts[0]}-modal` : undefined;


        let navArgs = [];

        let componentPart = pathParts[0];

        if (pathParts.length > 1) {
            navArgs = [...pathParts];
        }
        navArgs.push(`${componentPart}-interactor`);
        if (pathArgs?.length) {
            navArgs.push(pathArgs);
        }

        this.internalRoute = path;
        this.breadcrumb = breadcrumb;
        this.modalTitle = modalTitle;
        this.originalNavigation = this.router.routerState.snapshot.url;

        if (modulePath) navArgs.unshift(modulePath);

        this.router.navigate([{ outlets: { interactormodaloutlet: navArgs } }], { skipLocationChange: true, preserveFragment: false });
        this.componentDataInitializerFunc = initializer;
    }

    public openNestedComponentInModal(type: Type<any>, initializer: (c: ComponentRef<any>, openedModal: NgbActiveModal, parentModal: GenericModalComponent) => void) {
        this.injectedComponentType = type;
        this.componentDataInitializerFunc = initializer;
    }

    closeModal() {
        this.uiStateService.activeTaskEventEmitter.next(null);
        if (this.originalNavigation) {
            this.router.navigate([{ outlets: { interactormodaloutlet: null } }], { skipLocationChange: true, preserveFragment: false });
        }
        this.activeModal.close();

    }

    cancelModal() {
        this.uiStateService.activeTaskEventEmitter.next(null);
        if (this.originalNavigation) {
            this.router.navigate([{ outlets: { interactormodaloutlet: null } }], { skipLocationChange: true, preserveFragment: false });
        }
        this.activeModal.dismiss();

    }

    setModalData(modal: NgbModalRef, breadcrumb: string, modalTitle: string) {
        this.activeModal = modal;
        this.breadcrumb = breadcrumb;
        this.modalTitle = modalTitle;
    }
}
