<div [ngClass]="{ 'card': isInventoryContext || isShopManagementContext || containingShop }">
    <div
        [ngClass]="{
            'card-body ': isInventoryContext || isShopManagementContext || containingShop,
            'quest-item-holder': isQuestContext
        }"
        (click)="onClick()">
        <div class="item-img-quantity">
            <img
                class="item-image"
                [ngClass]="{ 'purchase-item': purchased }"
                [src]="purchased ? 'assets/images/icons/icon-check.svg' : item.ItemType.ImagePath" />
            <div class="item-count">{{ quantity }}</div>
        </div>
        <h3 class="item-name">{{ item.ItemType.Name }}</h3>
        @if (!isShopManagementContext && $any(item).Price && $any(item).Price > 0) {
            <p>Price: {{ $any(item).Price }} <img src="assets/images/icons/icon-critter-coin.svg" class="text-icon invert" /></p>
        }

        @if (isShopManagementContext) {
            <div class="form-floating">
                <input class="form-control" id="floatingPrice" type="text" [(ngModel)]="$any(item).Price" placeholder="price" required />
                <label for="floatingPrice">Price</label>
            </div>
            <div class="form-floating">
                <textarea
                    class="form-control"
                    id="floatingDescription"
                    type="text"
                    [(ngModel)]="$any(item).ShopDescription"
                    placeholder="Description"
                    required
                    style="height: 80px"></textarea>
                <label for="floatingDescription">Custom Description</label>
            </div>
            <button (click)="updateItem($any(item))" class="btn btn-primary">Update Item</button>
            <button (click)="removeItem($any(item))" class="btn btn-danger">Remove Item</button>
        }
        @for (option of baseOptions; track option) {
            <button class="btn" [class]="option.buttonClasses" type="button" (click)="doOption(option, $event)">{{ option.text }}</button>
        }
    </div>
</div>
