import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GenericSelectorTargets, InteractionType, QuestTaskStatusType, UIContextAction } from 'angular-to-phaser';
import { Observable, defer, of } from 'rxjs';
import { ITakeServerMetadata, NestableAuthenticatedView } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { Action, CashBalanceTaskStatus, Interaction, InteractionReply, NpcStateTaskStatus, PossessionTaskStatus, QuestStatus, QuestTaskStatus, } from 'src/app/dto';
import { AbstractService } from 'src/app/services/abstractservice';
import { ApplicationService } from 'src/app/services/application.service';
import { JsonEnrichmentService } from 'src/app/services/jsonmapper.service';
import { WorldService } from 'src/app/services/world.service';
import { GenericSelectorComponent } from '../../generic-selector/generic-selector.component';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
    selector: 'app-interactor',
    templateUrl: './interactor.component.html',
    styleUrls: ['./interactor.component.scss']
})
export class InteractorComponent extends NestableAuthenticatedView implements OnInit {

    private MODAL_OPTIONS_ROUTE: NgbModalOptions = { backdrop: 'static', scrollable: true, size: 'xl' };
    private MODAL_OPTIONS_INTERACTOR: NgbModalOptions = { backdrop: 'static', scrollable: false, size: 'lg' };

    public InteractionType = InteractionType;
    public QuestTaskStatusType = QuestTaskStatusType;

    public activeModal: NgbActiveModal;
    @Input() injectedClass: string;
    protected modalTitle: string;


    @Input() injectedInteraction: Interaction;//these 3 are all the same!!!!!
    interaction: Interaction;
    questInteraction: QuestStatus;

    @Input() parentInteraction: InteractorComponent;
    @Input() newChain: boolean = false;
    @Input() breadcrumb: string;

    private disabledInteractions: Interaction[] = [];

    constructor(route: ActivatedRoute, router: Router, private coreAppService: ApplicationService,
        private worldService: WorldService, private modalService: NgbModal, private httpClient: HttpClient, private jsonMapper: JsonEnrichmentService) {
        super(router, route, null, coreAppService.authService, MainpageComponent.route, coreAppService.uiStateService);
    }

    ngOnInit(): void {
        if (this.injectedInteraction) {
            this.beginInteraction(this.injectedInteraction, this.breadcrumb);
        }
    }

    public beginInteraction(interaction: Interaction, modalBreadcrumb: string) {
        this.breadcrumb = modalBreadcrumb;
        this.modalTitle = interaction.Title ?? interaction.Message;
        this.removeDisabledInteractions(interaction);

        this.interaction = interaction;
        if (interaction.InteractionType == InteractionType.Quest) {
            this.questInteraction = new QuestStatus(<QuestStatus>interaction, this.jsonMapper.jsonEnricher());
        }
        this.scrollToInteractionView();
    }

    public sendReply(interactionReply: InteractionReply) {
        if (interactionReply.Actions && interactionReply.Actions.length) {
            this.handleReplyActions(interactionReply).subscribe();
        } else {
            if (interactionReply.UIContextAction == UIContextAction.Dismiss) { this.terminateCurrentInteraction(); }
            if (interactionReply.UIContextAction == UIContextAction.TerminateInteractionChain) { this.terminateInteractionChain(); }
        }
    }


    public terminateCurrentInteraction() {
        if (this.interaction) {
            this.interaction.interactorDisabled = true;
            if (this.parentInteraction) {
                this.parentInteraction.removeChildFromParent(this.interaction);
            }
        }
        this.interaction = null;
        this.questInteraction = null;
        if (this.activeModal) {
            this.activeModal.dismiss();
        }
    }

    private removeChildFromParent(child: Interaction) {
        if (this.interaction && child) {
            this.disabledInteractions.push(child); //throw baby!
            if (!this.interaction.removeFromNestedQuests(child)) {
                this.interaction.removeFromNestedResponses(child);
            }
        }
    }

    private replaceChildForParent(child: Interaction, replacement: Interaction) {
        if (this.interaction && child && replacement) {
            this.interaction.replaceInNestedQuests(child, replacement);
        }
    }

    private terminateCurrentInteractionAndChain() {
        this.terminateInteractionChain();
        this.terminateCurrentInteraction();
    }

    private terminateInteractionChain() {
        if (this.parentInteraction && !this.newChain) {
            this.parentInteraction.terminateCurrentInteractionAndChain();
        } else if (this.parentInteraction) {
            this.parentInteraction.removeChildFromParent(this.interaction);
        }
    }

    private replaceInteractionChain(replacement: Interaction) {
        if (this.parentInteraction && !this.newChain) {
            this.parentInteraction.replaceInteractionChain(replacement);
            this.terminateCurrentInteraction();
        }
        else {
            this.parentInteraction.replaceChildForParent(this.interaction, replacement);
            this.beginInteraction(replacement, this.breadcrumb);
        }
    }



    public castPossessionTask(task: QuestTaskStatus): PossessionTaskStatus {
        return <PossessionTaskStatus>task;
    }

    public castNpcTask(task: QuestTaskStatus): NpcStateTaskStatus {
        return <NpcStateTaskStatus>task;
    }

    public castCashBalance(task: QuestTaskStatus): CashBalanceTaskStatus {
        return <CashBalanceTaskStatus>task;
    }


    private scrollToInteractionView(): void {
        setTimeout(() => {
            const actionBoxDiv = document.getElementById('interaction-view');
            if (actionBoxDiv) {
                const actionBox = actionBoxDiv.getBoundingClientRect();
                document.getElementById('main').scrollTo({ left: 0, top: actionBox.y - actionBox.height / 3, behavior: 'smooth' });
            }
        }, 200);
    }

    private handleReplyActions(interactionReply: InteractionReply): Observable<void> {
        return defer(() => {
            this.breadcrumb = this.breadcrumb ?? interactionReply.OptionText;
            for (let action of interactionReply.Actions) {

                if (action.DirectAction) {
                    this.subscriptions.push(
                        this.worldService.submitAction(action.DirectAction.ExternalId, action.DirectAction.ActionCode, action.DirectAction.RecipientType, this.coreAppService.UserArgumentBag)
                            .subscribe(interaction => this.handleInteractionChange(interaction, interactionReply.UIContextAction)));
                } else if (action.Link) {
                    if (action.Link.Verb) { //API call
                        let httpCall: Observable<Interaction>;
                        switch (action.Link.Verb) {
                            case 'PUT': httpCall = this.httpClient.put<Interaction>(action.Link.Path, this.coreAppService.UserArgumentBag, AbstractService.httpOptionsAuthJson(this.authService.jwtToken)); break;
                            case 'POST': this.httpClient.post<Interaction>(action.Link.Path, this.coreAppService.UserArgumentBag, AbstractService.httpOptionsAuthJson(this.authService.jwtToken)); break;
                            case 'GET': this.httpClient.get<Interaction>(action.Link.Path, AbstractService.httpOptionsAuthJson(this.authService.jwtToken)); break;
                            case 'DELETE': this.httpClient.post<Interaction>(action.Link.Path, AbstractService.httpOptionsAuthJson(this.authService.jwtToken)); break;
                            case 'PATCH': this.httpClient.post<Interaction>(action.Link.Path, null, AbstractService.httpOptionsAuthJson(this.authService.jwtToken)); break;
                        }
                        httpCall.subscribe(interaction => this.handleInteractionChange(interaction, interactionReply.UIContextAction));
                    } else { //Navigate the page
                        if (interactionReply.UIContextAction === UIContextAction.NewModalContext || interactionReply.UIContextAction === UIContextAction.ModalReplaceCurrentContext) {
                            this.openNewModal(action.NextEvent, null, interactionReply, action, interactionReply.OptionText);
                            if (interactionReply.UIContextAction === UIContextAction.ModalReplaceCurrentContext) {
                                this.terminateCurrentInteraction();
                            }
                        }
                        else {//(interactionReply.UIContextAction === UIContextAction.MaintainCurrentContext || interactionReply.UIContextAction === UIContextAction.Dismiss) {
                            this.router.navigate([action.Link.Path]);
                        }
                    }
                } else if (action.NextEvent) {
                    this.breadcrumb = this.breadcrumb ?? interactionReply.OptionText
                    this.handleInteractionChange(action.NextEvent, interactionReply.UIContextAction);
                }
            }
            return of(void 0);
        });
    }

    private removeDisabledInteractions(newInteraction: Interaction) {
        if (this.interaction == null || newInteraction == null) return;

        if (this.disabledInteractions && this.disabledInteractions.length > 0) {
            this.disabledInteractions.forEach(disabled => {
                if (newInteraction.removeFromNestedQuests(disabled)) {
                    if (!this.interaction.removeFromNestedQuests(disabled)) {
                        this.interaction.removeFromNestedResponses(disabled);
                    }
                }
            });
        }
    }

    private handleInteractionChange(newInteraction: Interaction, uiContext: UIContextAction) {
        switch (uiContext) {
            case UIContextAction.Dismiss: {
                this.terminateCurrentInteraction();
                break;
            }
            // @ts-expect-error
            case UIContextAction.MaintainCurrentContextTerminateChain: {
                this.terminateInteractionChain();
                //fallthrough to Maintain :)
            }
            case UIContextAction.MaintainCurrentContext: {
                this.beginInteraction(newInteraction, this.breadcrumb);
                break;
            }
            case UIContextAction.NewModalContext: {
                this.openNewModal(newInteraction, this.breadcrumb, null, null, this.modalTitle);
                break;
            }
            case UIContextAction.TerminateInteractionChain: {
                this.terminateCurrentInteractionAndChain();
                break;
            }
            case UIContextAction.ModalReplaceCurrentContext: {
                this.terminateInteractionChain();
                this.openNewModal(newInteraction, this.breadcrumb, null, null, this.modalTitle);
                break;
            }
            case UIContextAction.ReplaceCurrentInteractionChain: {
                this.replaceInteractionChain(newInteraction);
                break;
            }
            default: throw `Interactor missing mapping for UIContextAction ${uiContext}, meaning this interaction is busted`
        }
    }


    private openNewModal(interaction: Interaction, interactionBreadcrumbTitle: string, parentReply: InteractionReply, nestedAction: Action, modalTitle: string) {

        if (interaction && !nestedAction) {
            this.coreAppService.uiStateService.openModalWithComponent(
                this.MODAL_OPTIONS_INTERACTOR,
                interactionBreadcrumbTitle,
                modalTitle,
                InteractorComponent,
                GenericModalComponent,
                this.initializeInteractorModal(interaction, interactionBreadcrumbTitle));

        } else if (nestedAction.Link) {
            this.MODAL_OPTIONS_ROUTE.fullscreen = true;
            this.coreAppService.uiStateService.openModalWithRoute(
                this.MODAL_OPTIONS_ROUTE,
                nestedAction.Link.Path,
                this.breadcrumb ?? parentReply.OptionText,
                modalTitle ?? this.modalTitle,
                GenericModalComponent,
                this.initializeRoutedComponentModal(nestedAction.Link.Title, nestedAction.Link.Message, nestedAction.Link.Target, () => true, nestedAction.Link.InjectedClass),
                interaction ? (() => this.beginInteraction(interaction, interactionBreadcrumbTitle)).bind(this) : null);
            if (interaction) {
                //red-shrug-guy
            }
        }
    }

    /**
     * Function that builds a function.
     * Returned function is an initializer func, when invoked it will invoke init methods for the nested component.
     * This function is for initializing an interactor inside of a GenericModal
     * Outer (builder) func arguments are captured by closure and used by returned builder at invocation time.
     * JS sucks dude
     */
    private initializeInteractorModal = (interaction: Interaction, interactionBreadcrumbTitle: string) => //func that returns a func to build a closure. JS sucks dude.
        (
            interactor: InteractorComponent,
            openedModal: NgbActiveModal,
            parentModal: GenericModalComponent
        ) => {
            if (this.interaction != null) {
                interactor.parentInteraction = this;
            }
            interactor.beginInteraction(interaction, interactionBreadcrumbTitle);
            interactor.activeModal = openedModal;
            interactor.injectedClass = (interactor.parentInteraction ? 'nested-' : '') + 'modal-'
                + (interaction.InteractionType == InteractionType.Interaction ?
                    'interaction' : interaction.InteractionType == InteractionType.Quest ? 'quest' : interaction.InteractionType == InteractionType.QuestPreview ? 'quest-preview' : '')
        };



    /**
     * Function that builds a function to initialize a Routed Component inside a GenericModal (routed components are passed by their /url/path, not a ComponentRef)
     * Returned function is an initializer func, when invoked it will invoke init methods for the nested component.
     * Outer (builder) func arguments are captured by closure and used by returned builder at invocation time.
     * This is currently built primarily to support GenericSelector components, but its not limited that way.
     * JS sucks dude
     */
    private initializeRoutedComponentModal = (title: string,
        message: string,
        genericSelectorTarget: GenericSelectorTargets,
        genericSelectorFunction: (item: any) => boolean,
        injectedClass: string) =>
        (
            routedComponent: any /** COMPONENT FROM A COMPONENTREF.INSTANCE */,
            openedModal: NgbActiveModal,
            parentModal: GenericModalComponent
        ) => {

            parentModal.injectedClass = injectedClass;
            if (routedComponent.initSelectorTarget) { //basically a type check :(
                (<GenericSelectorComponent>routedComponent).initSelectorTarget(genericSelectorTarget, genericSelectorFunction);
            }

            if (routedComponent.acceptServerInput) { //interface check
                (<ITakeServerMetadata>routedComponent).acceptServerInput(title, message, injectedClass);
            }

            if (routedComponent.initModalParentForNav) { //interface check
                (<GenericSelectorComponent>routedComponent).initModalParentForNav(<any>parentModal)
            }
        }
}
