<div class="row">
    <p>{{ message }}</p>
</div>

@if (items && items.length) {
    <div class="row selection-list">
        @switch (selectorTarget) {
            @case (targets.Shops) {
                <label *ngFor="let item of items" class="selection-item col-md-3" [ngClass]="injectedClass">
                    <input type="checkbox" name="selection" class="checkbox" (click)="selectItem(item)" />
                    <div class="tile" [class.selected-item]="selectedItems.includes(item)">
                        <h3>{{ selectProperty(item) }}</h3>
                    </div>
                </label>
            }
            @case (targets.InventoryItems) {
                @for (item of items; track item) {
                    <label class="selection-item col-md-3" [ngClass]="injectedClass">
                        <div class="tile" [class.selected-item]="selectedItems.includes(item)">
                            <app-item-card [item]="item" class="item-holder"></app-item-card>
                            <input type="checkbox" name="selection" class="checkbox" (click)="selectItem(item)" />
                        </div>
                    </label>
                }
            }
            @default {
                <div></div>
            }
        }
    </div>
} @else {
    <div class="row empty">
        <div class="col">There's nothing here!</div>
    </div>
}

<div class="modal-footer">
    <div class="d-flex justify-content-end">
        <button class="btn btn-primary text-end" type="submit" [disabled]="selectedItems.length == 0" (click)="finalizeSelection()">
            Okay
        </button>
    </div>

    <button type="button" class="btn btn-outline-dark" (click)="cancel()">Cancel</button>
</div>
