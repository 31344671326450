import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GameStateResponse, InventoryItem, InventoryResponse, ItemTypeConfig, ItemUse, ShopItem } from '../dto';
import { AbstractService } from './abstractservice';
import { ApplicationService } from './application.service';
import { JsonEnrichmentService } from './jsonmapper.service';
import { PetService } from './pet.service';

@Injectable({
    providedIn: 'root'
})
export class InventoryService extends AbstractService {

    public ActiveUserInventory: BehaviorSubject<InventoryItem[]> = new BehaviorSubject<InventoryItem[]>([]);
    private inventoryInitialized = false;

    constructor(private http: HttpClient, private petService: PetService, private jsonMapper: JsonEnrichmentService, private coreAppService: ApplicationService) {
        super(coreAppService.authService);
    }

    initializeInventory(): void {
        if (!this.inventoryInitialized) {
            this.getInventory().subscribe(inv => {
                this.inventoryInitialized = true;
                this.ActiveUserInventory.next(inv);
            });
        }
    }


    public getInventory(): Observable<InventoryItem[]> {
        return this.http.get<InventoryResponse>(environment.apiUrl + '/inventory/', this.httpOptionsAuthJson())
            .pipe(
                map(invResponse => {
                    invResponse = this.jsonMapper.map(invResponse, InventoryResponse);
                    return invResponse.Items;
                }),
                retry(2),
                catchError(this.handleError)
            );
    }

    public insertItem(item: InventoryItem): void {
        if (!item) { return; }

        const inventory = this.ActiveUserInventory.getValue();
        inventory.unshift(item);
        this.ActiveUserInventory.next(inventory);
    }

    public insertItems(...items: InventoryItem[]): void {
        if (!items) { return; }

        const inventory = this.ActiveUserInventory.getValue();
        inventory.unshift(...items);
        this.ActiveUserInventory.next(inventory);
    }

    public removeItems(...items: InventoryItem[]): void {
        this.removeItemsById(...items.map(i => i.Id));
    }

    public removeItemsById(...itemIds: number[]): void {
        if (!itemIds) { return; }

        const inventory = this.ActiveUserInventory.getValue();
        const idxes: number[] = [];
        for (let i = 0; i < itemIds.length; i++) {
            const removeIdx = inventory.findIndex(iid => iid.Id === itemIds[i]);
            if (removeIdx !== -1) {
                idxes.push(removeIdx);
            }
        }
        idxes.sort((a, b) => b - a).forEach(idx => inventory.splice(idx, 1));
        this.ActiveUserInventory.next(inventory);
    }

    public getItemUses(itemType: ItemTypeConfig): Observable<ItemUse[]> {
        return this.http.get<ItemUse[]>(environment.apiUrl + `/inventory/uses/${itemType.Id}`, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    public useItem(item: InventoryItem, itemFunctionality: number, targetId: number): Observable<GameStateResponse> {
        return this.http.post<GameStateResponse>(environment.apiUrl + `/inventory/use/${item.Id}/${itemFunctionality}/${targetId ?? ''}`, null, this.httpOptionsAuthJson())
            .pipe(
                map(gameStateResponse => new GameStateResponse(gameStateResponse, this.jsonMapper.jsonEnricher())),
                tap({
                    next: result => GameStateResponse.distributeGameStateToServices(result, this, this.coreAppService.userService, this.petService)
                }),
                retry(2),
                catchError(this.handleError)
            );
    }

    public dropItem(item: InventoryItem): void {
        this.http.delete(environment.apiUrl + '/inventory/' + item.Id, this.httpOptionsAuthJson())
            .subscribe(() => this.removeItems(item));
    }

    public giftItem(item: InventoryItem, recipient: string): void {
        this.http.patch(environment.apiUrl + '/inventory/' + item.Id + '/' + recipient, null, this.httpOptionsAuthJson())
            .subscribe(() => {
                this.removeItems(item);
                this.coreAppService.modalService.dismissAll();
            });
    }

    public moveItemtoShop(item: InventoryItem, shop: number): void {
        const sitem = new ShopItem();
        sitem.Id = item.Id;
        sitem.ShopId = shop;
        sitem.Price = null;
        sitem.ShopDescription = null;


        this.http.put(environment.apiUrl + '/shop/stock/', sitem, this.httpOptionsAuthJson())
            .subscribe(() => {
                this.removeItems(item);
                this.coreAppService.modalService.dismissAll();
            });
    }

    public getItemTypeConfigs(itemTypeIds: number[]) {
        return this.coreAppService.unifiedRepo.getOrRetrieve(true, true, ItemTypeConfig, ...itemTypeIds);
    }

}
