import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PetUpdateType } from 'angular-to-phaser';
import { NestableAuthenticatedView } from 'src/app/components/base.component';
import { MainpageComponent } from 'src/app/components/mainpage/mainpage.component';
import { GameStateResponse, PetDTO, PetResult } from 'src/app/dto';
import { AuthService } from 'src/app/services/auth.service';
import { PetService } from 'src/app/services/pet.service';
import { UIStateService } from 'src/app/services/ui-state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-critter-state-update',
    templateUrl: './critter-state-update.component.html',
    styleUrls: ['./critter-state-update.component.scss']
})
export class CritterStateUpdateComponent extends NestableAuthenticatedView implements OnInit, AfterViewInit {

    @Input() stateUpdate: GameStateResponse;
    petUpdates: Map<PetDTO, PetResult[]> = new Map<PetDTO, PetResult[]>();

    constructor(private userService: UserService, private petService: PetService, private modalService: NgbModal, route: ActivatedRoute, router: Router, authService: AuthService, uiStateService: UIStateService) {
        super(router, route, null, authService, MainpageComponent.route, uiStateService);
    }


    ngOnInit(): void {
        this.initPetUpdates();
    }

    ngAfterViewInit(): void {
        this.initPetUpdates();
    }

    private initPetUpdates() {
        if (this.stateUpdate?.PetChanges && this.petUpdates.size === 0) {
            let petMap = this.petService.activeUserPets.value;
            this.stateUpdate?.PetChanges?.forEach(petResult => {

                var pet = petMap.find(p => p.Id === petResult.PetId);
                if (petResult.ChangedStat == PetUpdateType.Mood) {
                    pet.UpdateMood(petResult.FinalState, petResult.FinalStateMoodImagePath);
                }
                if (this.petUpdates.has(pet)) {
                    this.petUpdates.get(pet).push(petResult);
                } else {
                    this.petUpdates.set(pet, [petResult]);
                }
            });
        }
    }

    public startAnimation() {
        (<any>document.querySelector(`#${this.stateUpdate.LottieId}`)).play();
    };
}
