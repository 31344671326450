@if (interaction) {
    <div [ngClass]="injectedClass">
        <div class="row info adhere-left-edge">
            @if (interaction.ImagePath) {
                <div class="col-4">
                    <img [src]="interaction.ImagePath" class="quest-img" />
                </div>
            }
            <div class="col">
                @if (interaction.Title) {
                    <h2 class="interaction-title">{{ interaction.Title }}</h2>
                }
                @if (interaction.Message) {
                    <p id="interaction-message">{{ interaction.Message }}</p>
                }
                @if (questInteraction && questInteraction.QuestTasks && questInteraction.QuestTasks.length > 0) {
                    <div>
                        <strong>Requirements:</strong>
                        @for (task of questInteraction.QuestTasks; track task) {
                            <div class="requirements">
                                @if (task.QuestTaskStatusType == QuestTaskStatusType.PossessionTaskStatus) {
                                    @if (castPossessionTask(task); as possession) {
                                        @for (requirement of possession.ItemRequirementStatuses; track requirement) {
                                            <div class="required-item">
                                                <app-item-card
                                                    [item]="requirement.AsItem"
                                                    [quantity]="requirement.RequiredCount"
                                                    [isQuestContext]="true"
                                                    class="item-holder"></app-item-card>
                                            </div>
                                        }
                                    }
                                }
                                @if (task.QuestTaskStatusType == QuestTaskStatusType.CashBalanceTaskStatus) {
                                    @if (castCashBalance(task); as cash) {
                                        <p>
                                            {{ cash.RequiredCashBalance }}
                                            <img src="assets/images/icons/icon-critter-coin.svg" class="text-icon invert" />
                                        </p>
                                    }
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
        @if (interaction.Responses && interaction.Responses.length > 0) {
            <div class="row adhere-left-edge">
                <div class="col">
                    <div class="responses-container">
                        @for (reply of interaction.Responses; track reply) {
                            @if (!reply.DisplayMetadata.IsLink) {
                                <button
                                    type="button"
                                    class="btn shadow-sm"
                                    [ngClass]="{
                                        'btn-secondary btn-xl': interaction.Responses.length == 1,
                                        'btn-secondary order-last': reply.DisplayMetadata.IsPrimaryOption,
                                        'btn-danger order-first': reply.DisplayMetadata.IsDangerOption,
                                        'btn-light': !reply.DisplayMetadata.IsDangerOption && !reply.DisplayMetadata.IsPrimaryOption
                                    }"
                                    (click)="sendReply(reply)">
                                    {{ reply.OptionText }}
                                </button>
                            }
                            @if (reply.DisplayMetadata.IsLink) {
                                <div style="flex-basis: 100%; height: 0"></div>
                                <a (click)="sendReply(reply)">
                                    {{ reply.OptionText }}
                                </a>
                                <div style="flex-basis: 100%; height: 0"></div>
                            }
                        }
                    </div>
                </div>
            </div>
        }
        @if (interaction.Quests && interaction.Quests.length > 0) {
            @for (newQuest of interaction.Quests; track newQuest; let i = $index) {
                <div class="quest-list">
                    <h3 class="h6 text-uppercase">Quests</h3>
                    @if (!newQuest.interactorDisabled) {
                        <div class="accordion accordion-flush" [id]="'questAccordion' + i">
                            <div class="accordion-item">
                                <h4 class="h5 accordion-header" id="headingOne">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#collapse' + i"
                                        aria-expanded="true"
                                        [attr.aria-controls]="'collapse' + i">
                                        <strong>{{ newQuest.Title }}</strong>
                                    </button>
                                </h4>
                                <div
                                    [id]="'collapse' + i"
                                    class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="'heading' + i"
                                    [attr.data-bs-parent]="'#questAccordion' + i">
                                    <div class="accordion-body">
                                        <app-interactor
                                            [injectedInteraction]="newQuest"
                                            [injectedClass]="'nested-quest-preview'"
                                            [parentInteraction]="this"
                                            [newChain]="true"
                                            [breadcrumb]="this.breadcrumb"></app-interactor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        }
        <!----the ugliest if test of all time-->
        @if (
            interaction &&
            ((interaction.PlayerStateUpdate &&
                (interaction.PlayerStateUpdate.CashChangedAmount ||
                    (interaction.PlayerStateUpdate.AddedItems && interaction.PlayerStateUpdate.AddedItems.length > 0) ||
                    (interaction.PlayerStateUpdate.RemovedItems && interaction.PlayerStateUpdate.RemovedItems.length > 0))) ||
                (interaction.PetChanges && interaction.PetChanges.length > 0))
        ) {
            <div class="row results-container">
                <div class="col-auto">
                    <div id="interaction-view" class="results card">
                        <app-critter-state-update [stateUpdate]="interaction"></app-critter-state-update>
                    </div>
                </div>
            </div>
        }
    </div>
}
