import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, catchError, map, of, retry, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Channel, Message, MessageResponse, SendMessageResponse, UserProfile } from '../dto';
import { AbstractService } from './abstractservice';
import { ApplicationService } from './application.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseMessagingService extends AbstractService {

    public AllChannelsSubject$: BehaviorSubject<Map<number, BehaviorSubject<Channel>>> = new BehaviorSubject(new Map());

    constructor(protected coreAppService: ApplicationService, protected http: HttpClient, protected notificationService: NotificationService) {
        super(coreAppService.authService);
    }

    public getObservableChannels() {
        return this.AllChannelsSubject$.asObservable()
            .pipe(
                map(ch => Array.from(ch.values())
                    .map(channel => channel.getValue())),
                shareReplay(1));
    }

    /**
     * HTTP Helper function and primary view function: send a message and insert it in the view appropriately.
     * Updates Channel's BehaviorSubject
     */
    sendMessage(message: Message): void {
        this.http.post<SendMessageResponse>(environment.apiUrl + '/chat/message/', message, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                catchError(this.handleError),
            ).subscribe(sendResponse => {
                let activeUser = this.coreAppService.userService.activeUser$.getValue();
                message.IsMyMessage = true;
                message.SenderUsername = activeUser.Username;
                message.SendDate = new Date(sendResponse.SendDate);
                message.Id = sendResponse.MessageId;
                this.insertMessagesToChannel([message]);
            });
    }

    /**
     * Retrieves a page of messages from the server and adds them to their viewable parent channel.
     * If fetchNewest is false, 100 messages will be fetched backwards in time from the oldest currently loaded message, otherwise only fetches
     * newer messages that have occurred after initial page load.
     */
    public getMessagesPage(fetchNewest: boolean, channelId: number | null, publicOrPrivate: string | null): Observable<MessageResponse> {
        let lastMessageId = null;
        if (channelId && !fetchNewest) {
            let channel = this.AllChannelsSubject$.getValue().get(channelId)?.getValue();
            if (channel) {
                lastMessageId = channel.Messages[channel.Messages.length - 1].Id;
            }
        }
        return this.retrieveMessages(fetchNewest ? null : lastMessageId, channelId, publicOrPrivate);
    }

    /**
     * HTTP Helper function to create a new channel
     */
    public createChannel(createChannel: Channel, isPrivate: boolean): Observable<Channel> {
        return this.http.post<Channel>(`${environment.apiUrl}/chat/channel/${isPrivate}`, createChannel, this.httpOptionsAuthJson())
            .pipe(
                retry(2),
                map(channel => new Channel(channel, this.coreAppService.userService.activeUser$.getValue().Username)),
                tap(channel => this.updateChannels([channel])),
                catchError(this.handleError),
            );
    }

    /**
     * Creates a new channel and inserts it and sends a new message (if used)
     */
    public createChannelWithInitialMessage(createChannel: Channel, message: Message, isPrivate: boolean): Observable<Channel> {
        return this.createChannel(createChannel, isPrivate).pipe(
            map(channel => new Channel(channel, this.coreAppService.userService.activeUser$.getValue().Username)),
            tap(channel => {
                this.updateChannels([channel]);
                message.ChannelId = channel.Id;
                if (message.MessageText.length > 0) { this.sendMessage(message); };
            }));
    }

    public updateChannels(updatedChannels: Channel[]) {
        let newChannelSubjects = updatedChannels
            .map(updatedChannel => [updatedChannel.Id, this.updateAndReportIsChannelNew(updatedChannel)] as [number, BehaviorSubject<Channel>])
            .filter(newSubject => newSubject[1] != null);

        if (newChannelSubjects?.length) {
            let map = this.AllChannelsSubject$.getValue();
            newChannelSubjects.forEach(newIdChannel => map.set(newIdChannel[0], newIdChannel[1]));
            this.AllChannelsSubject$.next(map);
        }
    }

    private updateAndReportIsChannelNew(channel: Channel): BehaviorSubject<Channel> {
        let channelSubject = this.AllChannelsSubject$.getValue().get(channel.Id);
        if (channelSubject) {
            channel.Messages = this.mergeMessages(channel, channelSubject.getValue());
            channel.Messages.forEach(message => channel.ContainsNotification = channel.ContainsNotification || (message.targetingNotification && !message.targetingNotification.ReadDate));
            channelSubject.next(channel);
            return null;
        } else {
            let channelSubject = new BehaviorSubject(channel);
            channel.Messages.forEach(message => channel.ContainsNotification = channel.ContainsNotification || (message.targetingNotification && !message.targetingNotification.ReadDate));
            return channelSubject;
        }
    }

    /**
     * HTTP helper method to fetch channels by ID
     */
    public retrieveChannels(publicOrPrivate: string, ...channelIds: number[]): Observable<MessageResponse> {
        const pathParams = (channelIds?.length ? channelIds.join(',') : null) ?? publicOrPrivate;
        return this.http.get<MessageResponse>(environment.apiUrl + `/chat/channel/${pathParams}`, this.httpOptionsAuthJson())
            .pipe(
                map(msgResponse => {
                    let activeUser = this.coreAppService.userService.activeUser$.getValue();
                    msgResponse.Channels = msgResponse.Channels.map(channel => new Channel(channel, activeUser.Username));
                    return msgResponse;
                }),
                tap(msgResponse => this.updateChannels(msgResponse.Channels)),
                retry(2),
                catchError(this.handleError),
            );
    }

    public insertMessagesToChannel(messages: Message[], forceUpdate = false): Observable<{ channel: Channel; messages: Message[]; }> {
        if (!messages?.length) return of();
        let channelSubject = this.AllChannelsSubject$.getValue().get(messages[0].ChannelId);
        let channel = channelSubject?.getValue();
        if (!channel) {
            return this.retrieveChannels(null, messages[0].ChannelId)
                .pipe(
                    switchMap(newChannel => of({ channel: this.insertToChannelAndUpdateSubjects(newChannel.Channels[0], messages, forceUpdate), messages })));
        } else {
            return of({ channel: this.insertToChannelAndUpdateSubjects(channel, messages, forceUpdate), messages });
        }
    }

    public markNotificationsRead(...messagesWithNotifications: Message[]): void {
        if (!messagesWithNotifications?.length) return;
        this.notificationService.markNotificationsAsRead(...messagesWithNotifications.map(m => m.targetingNotification)).subscribe(() => {
            let channelMap = this.AllChannelsSubject$.getValue();
            new Set(messagesWithNotifications.map(m => m.ChannelId)).forEach(id => {
                var channel$ = channelMap.get(id);
                var channel = channel$?.getValue();
                channel.ContainsNotification = channel.Messages.findIndex(m => m.targetingNotification && !m.targetingNotification.ReadDate) >= 0;
                channel$.next(channel);
            })
        });
    }

    public markMessagesRead(...messages: Message[]): void {
        if (!messages?.length) return;
        this.http.patch(environment.apiUrl + '/chat/message/', messages.map(n => n.Id), this.httpOptionsAuthJson())
            .subscribe(() => {
                messages.forEach(m => m.Read = true);
                this.insertMessagesToChannel(messages, true);
            });
    }

    public retrieveChannelUserProfiles(channelId: number): Observable<UserProfile[]> {

        var channel = this.AllChannelsSubject$.getValue().get(channelId)?.getValue();
        if (channel === null) {
            return EMPTY;
        }
        return this.coreAppService.unifiedRepo.getOrRetrieve(true, true, UserProfile, ...channel.Usernames);

    }

    /** YOU MUST HAVE LOADED THE CHANNEL ALREADY */
    private insertToChannelAndUpdateSubjects(channel: Channel, messages: Message[], forceUpdate = false): Channel {

        if (!channel || !messages?.length) return channel;

        let oldMessages = channel.Messages;

        let nonInsertedMessages = messages.filter(message => {
            if (message.targetingNotification) {
                channel.ContainsNotification = channel.ContainsNotification || (message.targetingNotification && !message.targetingNotification.ReadDate);
            }

            let foundIndex = oldMessages.findIndex(msg => msg.Id === message.Id);
            if (!forceUpdate && foundIndex >= 0) { //no op, no dupes
                return false;
            } else if (foundIndex >= 0) {
                oldMessages[foundIndex] = message; //overwrite, no dupes
                return false;
            }
            return true;
        });

        if (nonInsertedMessages) {
            oldMessages.push(...nonInsertedMessages);
        }
        oldMessages.sort((a, b) => b.Id - a.Id);

        channel.Messages = oldMessages;
        let allChannels = this.AllChannelsSubject$.getValue();
        let channelSubject = allChannels.get(channel.Id);
        channelSubject.next(channel);
        this.AllChannelsSubject$.next(allChannels);
        return channel;
    }

    private mergeMessages(channelUpdate: Channel, oldChannel: Channel): Message[] {
        if (!channelUpdate || !oldChannel) return [];
        if (!channelUpdate.Messages) { channelUpdate.Messages = []; }
        if (!oldChannel.Messages) { oldChannel.Messages = []; }

        channelUpdate.Messages = channelUpdate.Messages.filter(msg => oldChannel.Messages.findIndex(chMsg => chMsg.Id === msg.Id) === -1); //no duplicates
        channelUpdate.Messages.sort((a, b) => b.Id - a.Id);
        oldChannel.Messages.unshift(...channelUpdate.Messages);
        oldChannel.Messages.sort((a, b) => b.Id - a.Id);

        return oldChannel.Messages;
    }

    /**
     * HTTP helper method to fetch another page of 100 messages starting beginning with the message with lastId and going back in time
     * If lastId is null, fetches the 100 most recent messages, if channel is null fetches 100 messages across all active channels (DMs, messageboards)
     */
    protected retrieveMessages(lastId: number | null, channelId: number | null, publicOrPrivate: string | null): Observable<MessageResponse> {
        if ((channelId == null && publicOrPrivate == null) || (channelId != null && publicOrPrivate != null)) {
            throw "Invalid Argument, needs correct channel OR public/private delineation";
        }
        return this.http.get<MessageResponse>(environment.apiUrl + `/chat/message/${channelId ? `channel/${channelId}/` : ''}` +
            `${publicOrPrivate ? publicOrPrivate + '/' : ''}` +
            `${lastId || channelId ? `page/${lastId ?? ''}` : ''}` +
            `${!lastId && !channelId ? 'all' : ''}`,
            this.httpOptionsAuthJson())
            .pipe(
                map(msgResp => {
                    if (!msgResp) {
                        msgResp = new MessageResponse(null, null);
                    }
                    if (msgResp.Channels) {
                        msgResp.Channels = msgResp.Channels.map(channel => new Channel(channel, this.coreAppService.userService.activeUser$.getValue().Username));
                    } else {
                        msgResp.Channels = [];
                    }
                    return msgResp;
                }),
                tap(msgResponse => this.updateChannels(msgResponse.Channels)),
                retry(2),
                catchError(this.handleError),
            );
    }

    /**
     * HTTP helper method to fetch a thread based on a parent message ID
     */
    private retrieveMessage(...id: number[]): Observable<MessageResponse> {
        return this.http.get<MessageResponse>(environment.apiUrl + `/chat/message/${id.join(',')}`, this.httpOptionsAuthJson())
            .pipe(
                map(msgResp => {
                    let activeUser = this.coreAppService.userService.activeUser$.getValue()
                    msgResp.Channels = msgResp.Channels.map(channel => new Channel(channel, activeUser.Username));
                    return msgResp;
                }),
                tap(msgResponse => this.updateChannels(msgResponse.Channels)),
                retry(2),
                catchError(this.handleError),
            );
    }

    /**
     * HTTP helper method to fetch a thread based on a parent message ID
     */
    private retrieveMessagesFromParent(parentId: number): Observable<MessageResponse> {
        return this.http.get<MessageResponse>(environment.apiUrl + `/chat/message/thread/${parentId}`, this.httpOptionsAuthJson())
            .pipe(
                map(msgResp => {
                    let activeUser = this.coreAppService.userService.activeUser$.getValue()
                    msgResp.Channels = msgResp.Channels.map(channel => new Channel(channel, activeUser.Username));
                    return msgResp;
                }),
                tap(msgResponse => this.updateChannels(msgResponse.Channels)),
                retry(2),
                catchError(this.handleError),
            );
    }


    public static sortChannelsByMessageRecency(allChannels: Channel[]): Channel[] {
        return allChannels.sort((channelA, channelB) => {
            let compareChannelA: number;
            let compareChannelB: number;
            if (channelA.Messages && channelA.Messages.length > 0) {
                compareChannelA = channelA.Messages[0].SendDate.getTime();
            } else {
                compareChannelA = channelA.CreateDate.getTime();
            }
            if (channelB.Messages && channelB.Messages.length > 0) {
                compareChannelB = channelB.Messages[0].SendDate.getTime();
            } else {
                compareChannelB = channelB.CreateDate.getTime();
            }
            return compareChannelB - compareChannelA;
        });
    }

}
