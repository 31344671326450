import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationType, SignalType } from 'angular-to-phaser';
import { BehaviorSubject, Observable, catchError, combineLatest, map, merge, shareReplay, switchMap, tap } from 'rxjs';
import { Channel, Friendship, Message, MessageResponse, Notification, NotificationSignal, Signal } from '../dto';
import { ApplicationService } from './application.service';
import { BaseMessagingService } from './base-messaging.service';
import { NotificationService } from './notification.service';
import { FriendshipToast, MessageToast, SignalService } from './signal.service';

@Injectable({
    providedIn: 'root'
})
export class InboxService extends BaseMessagingService {

    public allFriendAndReqs$: BehaviorSubject<Friendship[]> = new BehaviorSubject<Friendship[]>([]);
    public friendsList$: Observable<Friendship[]> = this.allFriendAndReqs$.pipe(
        map(friends => friends.filter(fd => fd.Accepted),
            shareReplay(1)));
    public friendRequests$: Observable<Friendship[]>;

    public onlineFriends$: BehaviorSubject<Friendship[]> = new BehaviorSubject<Friendship[]>([]);
    public offlineFriends$: BehaviorSubject<Friendship[]> = new BehaviorSubject<Friendship[]>([]);


    constructor(notificationService: NotificationService, private signalService: SignalService, coreAppService: ApplicationService, http: HttpClient) {
        super(coreAppService, http, notificationService);
        this.coreAppService.notificationService.latestNotificationSignal.subscribe(this.receiveNotification.bind(this));

        var authSubscription = coreAppService.userService.activeUser$.subscribe(user => {
            if (user && user.Username && this.authService.isAuthenticated()) {
                this.coreAppService.notificationService.initializeConnection();
                combineLatest([this.getPrivateMessagesPage(true), this.retrieveChannels('private')]).subscribe(() => {
                    authSubscription.unsubscribe(); //one and done
                });
            }
        });

        if (this.authService.isAuthenticated()) {
            this.initializeActiveUserFriends();
        } else {
            this.coreAppService.userService.activeUser$.subscribe(a => {
                if (!this.allFriendAndReqs$.getValue().length && this.authService.isAuthenticated()) {
                    this.initializeActiveUserFriends();
                }
            });
        }

        this.friendRequests$ = combineLatest([
            this.allFriendAndReqs$.pipe(map(friends => friends.filter(fd => !fd.Accepted))),
            this.notificationService.persistentPrivateNotifications.asObservable().pipe(map(ns => ns.filter(n => n.NotificationType == NotificationType.FriendshipRequest)))
        ]).pipe(
            map(
                (friendRequestsAndNotifications: [Friendship[], Notification[]]) => {
                    friendRequestsAndNotifications[0].forEach(frq =>
                        frq.targetingNotification = friendRequestsAndNotifications[1].find(note => frq.equals(<Friendship>note.Entity)));
                    return friendRequestsAndNotifications[0];
                }));


        this.notificationService.persistentPrivateNotifications.pipe(
            switchMap(notifications => {
                let channelGroupedNotifications = notifications
                    .filter(n => n.NotificationType == NotificationType.PrivateMessage)
                    .map(n => {
                        (<Message>n.Entity).targetingNotification = n;
                        return <Message>n.Entity;
                    })
                    .reduce(
                        (entryMap, e) => entryMap.set(e.ChannelId, [...entryMap.get(e.ChannelId) || [], e]),
                        new Map<number, Message[]>()
                    );
                let keyset = [...channelGroupedNotifications.keys()];
                let inserts$ = keyset.map(k => this.insertMessagesToChannel(channelGroupedNotifications.get(k), true))
                return merge(...inserts$);
            }),
            shareReplay(1)).subscribe();
    }

    public findPrivateChat(withUsername: string): Channel | null {
        const allPrivateChannels = Array.from(this.AllChannelsSubject$.getValue().values()).filter(ch => ch.getValue().Usernames.length === 2).map(ch => ch.getValue());
        const privateMessage = allPrivateChannels.find(ch => ch.Usernames.includes(withUsername));
        return privateMessage;
    }

    /**
       * Retrieves a page of messages from the server and adds them to their viewable parent channels.
       * If fetchNewest is false, 100 messages will be fetched backwards in time from the oldest currently loaded message, otherwise only fetches
       * newer messages that have occurred after initial page load.
       */
    public getPrivateMessagesPage(fetchNewest: boolean): Observable<MessageResponse> {
        let lastMessageId = null;
        return this.retrieveMessages(fetchNewest ? null : lastMessageId, null, 'private');
    }

    private initializeActiveUserFriends(): void {
        this.retrieveFriends().subscribe(friends => this.allFriendAndReqs$.next(friends ?? []));
        this.friendsList$.subscribe(fds => {
            //update friends list periodically
            this.retrieveOnlineFriends();
        });
        setInterval(this.retrieveOnlineFriends.bind(this), 60 * 1000);

    }

    public retrieveFriends(): Observable<Friendship[]> {
        return this.coreAppService.unifiedRepo.retrieveWithNoId(true, true, Friendship);
    }

    private retrieveOnlineFriends(): void {
        combineLatest([this.friendsList$,
        this.coreAppService.unifiedRepo.friendshipRepository.retrieveOnlineFriends()
        ]).subscribe(
            friendsAndOnlineFriendUsernames => {
                const allFriends = friendsAndOnlineFriendUsernames[0];
                if (allFriends) {
                    const onlines = allFriends.filter(x => friendsAndOnlineFriendUsernames[1].find(ofu => ofu === x.SenderUsername || ofu === x.RecipientUsername));
                    this.onlineFriends$.next(onlines);
                    this.deriveOfflineFriends(allFriends, onlines);
                }
            });
    }

    private deriveOfflineFriends(allFriends: Friendship[], onlineFriends: Friendship[]): void {
        if (allFriends) {
            if (this.onlineFriends$) {
                const offlines = allFriends.filter(all =>
                    !onlineFriends.find(on =>
                        on.RecipientUsername === all.RecipientUsername && on.SenderUsername === all.SenderUsername));
                this.offlineFriends$.next(offlines);
            } else {
                this.offlineFriends$.next(allFriends);
            }
        }
    }

    public addFriend(friendUsername: string): Observable<Friendship> {
        this.notificationService.markNotificationsAsRead(this.findFriendshipNotification(friendUsername)).subscribe();
        return this.coreAppService.unifiedRepo.friendshipRepository.addFriend(friendUsername)
            .pipe(
                switchMap(friend => this.coreAppService.jsonMapper.hydrate(friend, Friendship)
                    .pipe(
                        tap(friendshipHydrated => this.coreAppService.jsonMapper.cache(friendshipHydrated, Friendship))
                    )
                ),
                tap(friendship => {
                    const activeUsername = this.coreAppService.userService.activeUser$.getValue().Username;
                    this.addFriendWithoutDupes(activeUsername, friendUsername, friendship);
                }),
            );
    }

    private addFriendWithoutDupes(activeUsername: string, friendUsername: string, friendship: Friendship) {
        let friends = this.allFriendAndReqs$.getValue() ?? [];
        friends = friends.filter(frq => frq.FriendUsername.localeCompare(friendUsername) !== 0);//prevent dupes in case the Notification beat us to the punch
        friends.push(friendship);
        this.allFriendAndReqs$.next(friends);
    }

    public removeFriend(friendUsername: string): Observable<void> {
        this.notificationService.removeNotification(this.findFriendshipNotification(friendUsername));
        return this.coreAppService.unifiedRepo.friendshipRepository.removeFriend(friendUsername)
            .pipe(
                tap(() => {
                    const allFriendships = this.allFriendAndReqs$.getValue();
                    let removedFriend = allFriendships.find(f => f.FriendUsername.localeCompare(friendUsername) === 0)
                    this.coreAppService.unifiedRepo.uncacheItems(Friendship, removedFriend);
                    if (allFriendships !== null && allFriendships.length > 0) {
                        this.allFriendAndReqs$.next(allFriendships.filter(f => f !== removedFriend));
                    }
                }),
                catchError(this.handleError),
            );
    }

    private findFriendshipNotification(friendUsername: string) {
        var friend = this.allFriendAndReqs$.getValue().find(f => f.FriendUsername == friendUsername);
        return friend?.targetingNotification;
    }

    private receiveNotification(value: Signal) {
        if (value?.SignalType !== SignalType.Notification) return;

        let notification = (<NotificationSignal>value).Notification;
        if (notification.NotificationType != NotificationType.FriendshipRequest && notification.NotificationType != NotificationType.PrivateMessage) return;

        if (notification.NotificationType == NotificationType.FriendshipRequest) {

            let notificationFriendship = this.coreAppService.jsonMapper.map(<Friendship>notification.Entity, Friendship);
            const activeUser = this.coreAppService.userService.activeUser$.getValue();
            const activeUsername = activeUser.Username;

            const allFriends = this.allFriendAndReqs$.getValue();

            const extantRequestIndex = allFriends.findIndex(fr => fr.equals(notificationFriendship))

            if (extantRequestIndex >= 0) {
                if (!notificationFriendship.Accepted) {
                    allFriends.splice(extantRequestIndex, 1); //remove rejected requests
                    this.allFriendAndReqs$.next(allFriends);
                }
                else //overwrite the old request with the new friendship
                    this.addFriendWithoutDupes(activeUsername, notificationFriendship.FriendUsername, notificationFriendship);
            } else { //not in the list yet, meaning they just sent to use
                allFriends.push(notificationFriendship);
                this.allFriendAndReqs$.next(allFriends);
            }
            const toast = new FriendshipToast(value.AlertText, notificationFriendship, 7000, activeUser);

            if (toast.visibleDuration > 0) {
                this.signalService.addToast(toast);
            }
        } else if (notification.NotificationType == NotificationType.PrivateMessage) {
            let newMessage = <Message>notification.Entity;

            this.insertMessagesToChannel([newMessage]).subscribe((channelAndMessages) => {
                if (!newMessage.IsMyMessage) {
                    this.coreAppService.notificationService.addToast(
                        new MessageToast(newMessage.MessageText,
                            newMessage.SenderUsername,
                            channelAndMessages.channel,
                            3500));
                }
            });
        }
        this.notificationService.addNotification(notification);
    }
}
